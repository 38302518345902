<template>
	<div class="search-box">
		<div class="absolute left-[36px] center-col h-full py-[32px]">
			<Icon name="position" class="w-[28px]" />
			<span class="border border-dashed h-full" />
			<Icon name="location" class="w-[32px]" />
		</div>

		<div class="flex flex-col w-[87%] pl-[32px]">
			<LocationInput id="location" :modelValue="credentials.from.value" @change="(val) => credentials.from.value = val" class="input-field-search border-bottom border-[#EFF2F7] h-[56px]" placeholder="Pick up location" />
			<LocationInput id="destination" :modelValue="credentials.to.value" @change="(val) => credentials.to.value = val" class="input-field-search h-[56px]" placeholder="Search destination" />
		</div>
		<!-- <icon name="switch" class="h-10 absolute right-0" /> -->
	</div>
</template>

<script setup>
import { use_post_charter } from '../composables'
import LocationInput from './locationInput.vue'
import Icon from './icon.vue'

const { credentials } = use_post_charter()
</script>

<style scoped>
h1, h2, h3, h4, h5, h6, p, span{
	margin: 0 !important;
}
/* .search-box{
	@apply w-full flex justify-center items-center border border-[#EFF2F7] rounded-lg  mt-[20px] ;
} */

.search-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EFF2F7;
    border-radius: 10px;
    margin-top: 20px;
}
</style>
